import type {
  Command,
  Language,
  SessionOutput,
  SessionStatus,
} from '@datacamp/multiplexer-client';
import { wrap } from 'comlink';
import noop from 'lodash/noop';
import raven from 'raven-js';
// eslint-disable-next-line no-restricted-imports
import { Subject } from 'rxjs';
import type { Subscription } from 'rxjs/Subscription';

import unreachable from '../interfaces/unreachable';
import type { PyodideInitialisationContext } from '../workers/pyodide.worker';
import PyodideWorker from '../workers/pyodide.worker';

import type { CodeExecutionBackend, Subscriber } from './codeExecutionBackend';
import { encodeUnicodeText } from './unicode';

const HEARTBEAT_INTERVAL = 10000;

export type PyodideAPI = {
  cleanupExercise: (exerciseKey: string) => Promise<void>;
  heartbeat: (exerciseKey: string) => Promise<void>;
  initialisePyodide: (
    context: PyodideInitialisationContext,
    initCommand: Command,
  ) => Promise<void>;
  runConsoleCommand: (exerciseKey: string, command: Command) => Promise<string>;
  runSubmitCommand: (exerciseKey: string, command: Command) => Promise<string>;
};

export type PyodideBackend = CodeExecutionBackend & {
  options: {
    language: Language;
    userInfo: unknown;
  };
};

export const createPyodideBackend = ({
  userInfo,
}: {
  userInfo: unknown;
}): PyodideBackend => {
  let clientSubscribers: Array<(data: any) => void> = [];
  const sessionStatusSubscribers: Set<Subscriber<SessionStatus>> = new Set();
  const sessionOutputSubscribers: Set<Subscription> = new Set();

  const output$ = new Subject<SessionOutput[]>();

  const worker = new PyodideWorker();
  const pyodideAPI = wrap<PyodideAPI>(worker);
  let busyTasksCounter = 0;
  let exerciseKey: string | null = null;

  setInterval(async () => {
    if (exerciseKey) {
      await pyodideAPI.heartbeat(exerciseKey);
    }
  }, HEARTBEAT_INTERVAL);

  const updateStatus = (status: SessionStatus) => {
    sessionStatusSubscribers.forEach((subscriber) => {
      subscriber(status);
    });
  };

  const setBusy = () => {
    if (busyTasksCounter === 0) {
      updateStatus({ status: 'busy' });
    }
    busyTasksCounter += 1;
  };

  const setReady = () => {
    busyTasksCounter = Math.max(0, busyTasksCounter - 1);
    if (busyTasksCounter === 0) {
      updateStatus({ status: 'ready' });
    }
  };

  const runConsoleCommand = async (command: Command) => {
    if (exerciseKey) {
      const result = await pyodideAPI.runConsoleCommand(exerciseKey, command);
      output$.next(JSON.parse(result.toString()));
    }
  };

  const runSubmitCommand = async (command: Command) => {
    if (exerciseKey) {
      const result = await pyodideAPI.runSubmitCommand(exerciseKey, command);
      output$.next(JSON.parse(result.toString()));
    }
  };

  const runCommand = (command: Command) => {
    try {
      switch (command.command) {
        case 'console':
          return runConsoleCommand(command);
        case 'submit':
          return runSubmitCommand(command);
        case 'init':
        case 'run':
        case 'soft_init':
        case 'take_solution':
        case 'apply_solution':
        case 'code_completion':
        case 'sendValidationChunk':
        case 'startValidation':
        case 'isValidationDone':
        case 'getValidationResult':
        case 'view':
          throw new Error('not implemented');
        default:
          return unreachable(command.command);
      }
    } catch (error) {
      output$.next([
        {
          type: 'error',
          payload: error.toString(),
        },
      ]);
      raven.captureMessage('Pyodide error occurred while running the code', {
        level: 'warning',
        extra: { error, command },
        stacktrace: true,
      });
    }

    return Promise.resolve();
  };

  return {
    client: {
      on: (event, callback) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers.push(callback);
      },
      off: (event) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers = [];
      },
    },
    output$,
    start: async (_, initCommand) => {
      if (exerciseKey) {
        await pyodideAPI.cleanupExercise(exerciseKey);
      }

      const [, , course, chapter] = window.location.pathname.split('/');
      const exercise = window.location.search.replace(/^.+ex=(\d+).*/, '$1');
      exerciseKey = `${exercise}-${Date.now()}`;

      setBusy();

      try {
        await pyodideAPI.initialisePyodide(
          { course, chapter, exercise, exerciseKey },
          initCommand,
        );
      } catch (error) {
        raven.captureMessage(
          'Pyodide error occurred while initialising the session',
          {
            level: 'warning',
            extra: {
              error,
              encodedError: btoa(encodeUnicodeText(error.toString())),
              initCommand,
            },
            stacktrace: true,
          },
        );
        updateStatus({ status: 'broken' });
        return;
      }

      clientSubscribers.forEach((subscriber) => {
        subscriber({
          body: {
            id: 'pyodide_session',
          },
        });
      });

      setReady();
    },

    input: async (command) => {
      setBusy();
      try {
        await runCommand(command);
      } catch (error) {
        raven.captureMessage('Pyodide error occurred while running the code', {
          level: 'warning',
          extra: { error, command },
          stacktrace: true,
        });
        updateStatus({ status: 'broken' });
        return;
      }
      setReady();
    },
    stop: async () => {
      sessionOutputSubscribers.forEach((subscription) =>
        subscription.unsubscribe(),
      );
      sessionOutputSubscribers.clear();

      sessionStatusSubscribers.clear();
    },
    stopPolling: noop,
    subscribe: (subscriber) => {
      sessionStatusSubscribers.add(subscriber);
      return () => {
        sessionStatusSubscribers.delete(subscriber);
      };
    },
    subscribeToOutputs: (
      predicate,
      listener: (output: SessionOutput) => void,
    ) => {
      const filtered = output$.flatMap((outputs) => {
        return outputs.filter(predicate);
      });

      const subscription = filtered.subscribe(listener);

      sessionOutputSubscribers.add(subscription);

      return subscription;
    },
    options: {
      language: 'python',
      userInfo,
    },
  };
};
